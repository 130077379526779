<template>
    <v-card>
        <v-toolbar flat style="border-bottom: 1px solid #c0c0c0">
            <v-col>
                <v-btn color="primary" @click="saveSchedule()" v-if="adminOrManager" large>
                    Save Schedule
                </v-btn>
            </v-col>
            <v-spacer />

            <v-btn class="mx-2" fab dark small color="primary" :disabled="!calendarbuttonsenabled" @click="getPreviousDayPendingSchedules()">
                <v-icon dark>mdi-minus</v-icon>
            </v-btn>
            <v-chip class="ma-2"
            label
            large
            outlined
            color="secondary">
        {{ formatDate(this.date) }}
    </v-chip>
            
            <v-btn class="mx-2" fab dark small color="primary" :disabled="!calendarbuttonsenabled" @click="getNextDayPendingSchedules()">
                <v-icon dark>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
                <v-data-table :headers="headers"
                                :items="items"
                                :hide-default-footer="true"
                                :loading="loading"
                                item-key="id"
                              
                              :items-per-page="9999999"
                                must-sort>
                    <template v-slot:item.shiftA="props">
                            
                        <div style="padding: 0.5rem; width:200px" class="border-left border-primary">
                            <v-select v-model="props.item.shiftATech"
                                        :items="technicianList"
                                        label="Technician"
                                        item-text="user.userName"
                                        @input=setRowModified(props.item)
                                        item-value="user"
                                      :disabled="isReadOnly"
                                        hide-details
                                        dense
                                        outlined>
                            </v-select>
                        </div>
                    </template>
                    <template v-slot:item.shiftB="props">
                        <div style="padding: 0.5rem; width:200px" class="border-left border-primary">
                            <v-select v-model="props.item.shiftBTech"
                                        :items="technicianList"
                                        label="Technician"
                                        item-text="user.userName"
                                        @input=setRowModified(props.item)
                                        item-value="user"
                                      :disabled="isReadOnly"
                                        hide-details
                                        dense
                                        outlined>
                            </v-select>
                        </div>
                    </template>
                    <template v-slot:item.shiftC="props">
                        <div style="padding: 0.5rem; width:200px" class="border-left border-primary">
                            <v-select v-model="props.item.shiftCTech"
                                        :items="technicianList"
                                        label="Technician"
                                        item-text="user.userName"
                                        @input=setRowModified(props.item)
                                        item-value="user"
                                      :disabled="isReadOnly"
                                        hide-details
                                        dense
                                        outlined>
                            </v-select>
                        </div>
                    </template>
                    <template v-slot:item.shiftD="props">
                        <div style="padding: 0.5rem; width:200px" class="border-left border-primary">
                            <v-select v-model="props.item.shiftDTech"
                                        :items="technicianList"
                                        label="Technician"
                                        item-text="user.userName"
                                        @input=setRowModified(props.item)
                                        item-value="user"
                                      :disabled="isReadOnly"
                                        hide-details
                                        dense
                                        outlined>
                            </v-select>
                        </div>
                    </template>
                    <template v-slot:item.shiftE="props">
                        <div style="padding: 0.5rem; width:200px" class="border-left border-primary">
                            <v-select v-model="props.item.shiftETech"
                                        :items="technicianList"
                                        label="Technician"
                                        item-text="user.userName"
                                        @input=setRowModified(props.item)
                                        item-value="user"
                                      :disabled="isReadOnly"
                                        hide-details
                                        dense
                                        outlined>
                            </v-select>
                        </div>
                    </template>
                    <template v-slot:item.shiftF="props">
                        <div style="padding: 0.5rem; width:200px" class="border-left border-primary">
                            <v-select v-model="props.item.shiftFTech"
                                        :items="technicianList"
                                        label="Technician"
                                        item-text="user.userName"
                                        @input=setRowModified(props.item)
                                        item-value="user"
                                      :disabled="isReadOnly"
                                        hide-details
                                        dense
                                        outlined>
                            </v-select>
                        </div>
                    </template>
                </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import moment from "moment";
    import services from "@/services"
    import { ToSearchPageRequest } from "@/models"
    export default {
        name: "schedule",
        components: {

        },
         data () {
             return {
            shiftATech: {},
              shiftBTech: {},
              shiftCTech: {},
              shiftDTech: {},
              shiftETech: {},
              shiftFTech: {},
              modified: false,
              calendarbuttonsenabled: true,              
                 selectedUser: null,  
              sessionsToSave: [],
            loadingData: false,
            technicianList: [],
            searchText: "",
            totalCount: 1000,
            items: [],
            request: {
                outpatientSessionId: 0,
                startTime: new Date(),
                endTime: new Date(),
                user: {},
            },
            dates: [],
            date: {},
            displayDate: {},
            loading: false,
            options: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 10000,
                multiSort: false,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
      headers: [
                {
                    text: "Unit Number",
                    sortable: false,
                    value: "unitId",
          },
          {
                    text: "Status",
                    sortable: false,
                    value: "sessionStatus",
          },
            { text: "0000am - 0400am", value: "shiftA", align: "start" },
            { text: "0400am - 0800am", value: "shiftB", align: "start" },
            { text: "0800am - 1200pm", value: "shiftC", align: "start" },
            { text: "1200pm - 1600pm", value: "shiftD", align: "start" },
            { text: "1600pm - 2000pm", value: "shiftE", align: "start" },
            { text: "2000pm - 0000am", value: "shiftF", align: "start" },
            ]
    }
  },
        watch: {
            options: {
                async handler() {
                    await this.getPendingSchedules();
                },
                deep: true,
                
            },

            searchText: {
                async handler() {
                    if (this.timer) {
                        clearTimeout(this.timer);
                        this.timer = null;
                    }
                    this.timer = setTimeout(async () => {
                        await this.getPendingSchedules();
                    }, 1000);
                },
                deep: true,
            },
        },
        computed: {
            adminOrManager() {
                var adminOrManager = (this.$store.state.user.isAdmin || this.$store.state.user.isTechManager)
                return adminOrManager;
            },
            isReadOnly() {          
          return this.$store.state.user.isTech || this.$store.state.user.isReadOnly;
    }
        },
        methods: {
            getPendingSchedules() {
                this.loading = true;
                this.calendarbuttonsenabled = false; 
                this.date = this.$moment(this.date).tz('America/Chicago').startOf('day').format("LL");
                services.outpatientService.getOutpatientSessionsWithoutSchedules(this.date).then(data => {
                this.items = data;                
                this.totalCount = data.totalCount;                      
                }).finally(() => {
                    this.calendarbuttonsenabled = true;   
                    this.loading = false;                   
                });
            },
            getNextDayPendingSchedules() {
                this.loading = true;
                this.calendarbuttonsenabled = false;    
                this.date = this.$moment(this.date).add(1, "days").format("LL");                 
                services.outpatientService.getOutpatientSessionsWithoutSchedules(this.date).then(data => {
                    this.items = data;            
                this.totalCount = data.totalCount;     
                }).finally(() => {
                    this.calendarbuttonsenabled = true;    
                    this.loading = false;
                });
            },
            getPreviousDayPendingSchedules() {
                this.loading = true;
                this.calendarbuttonsenabled = false;   
                this.date = this.$moment(this.date).subtract(1, "days").format("LL"); 
                services.outpatientService.getOutpatientSessionsWithoutSchedules(this.date).then(data => {
                this.items = data;
                this.totalCount = data.totalCount;                    
                }).finally(() => {
                   this.calendarbuttonsenabled = true;    
                    this.loading = false;
                });
            },             
            async saveSchedule() {
                // need to reset itemsToSave when done.
                this.loading = true;
                var itemsToSave = this.items.filter(item => item.modified == true);

                for (const element of itemsToSave) {
                    await this.constructSessionDetail(element);
                }

                await services.sessionDetailService
                    .createbatch(this.sessionsToSave)
                    .then(response => {
                        if (response) {
                            this.$store.commit(
                                "snack/set_snack",
                                "Schedule saved."
                            )
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });

                this.items.forEach(item => item.modified == false);
                this.sessionsToSave = [];
            },
            async constructSessionDetail(session) {
                if (session.modified == false)
                    return;

                var dateToSave = this.date;
                    var requestObj = {};

                if (session.shiftATech !== null && session.shiftATech.id !== undefined) {
                        requestObj.outpatientSessionId = session.outpatientSessionId;
                        requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 0:00";
                        requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 4:00";
                    requestObj.user = session.shiftATech.id;
                        this.sessionsToSave.push(requestObj);
                    }

                if (session.shiftBTech !== null && session.shiftBTech.id !== undefined) {
                    requestObj = {};
                        requestObj.outpatientSessionId = session.outpatientSessionId;
                        requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 4:00";
                        requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 8:00";
                    requestObj.user = session.shiftBTech.id;
                        this.sessionsToSave.push(requestObj);
                    }

                if (session.shiftCTech !== null && session.shiftCTech.id !== undefined) {
                    requestObj = {};
                        requestObj.outpatientSessionId = session.outpatientSessionId;
                        requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 8:00";
                        requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 12:00";
                    requestObj.user = session.shiftCTech.id;
                        this.sessionsToSave.push(requestObj);
                    }

                if (session.shiftDTech !== null && session.shiftDTech.id !== undefined) {
                    requestObj = {};
                        requestObj.outpatientSessionId = session.outpatientSessionId;
                        requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 12:00";
                        requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 16:00";
                    requestObj.user = session.shiftDTech.id;
                        this.sessionsToSave.push(requestObj);
                    }

                if (session.shiftETech !== null && session.shiftETech.id !== undefined) {
                    requestObj = {};
                        requestObj.outpatientSessionId = session.outpatientSessionId;
                        requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 16:00"
                        requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 20:00"
                    requestObj.user = session.shiftETech.id;
                        this.sessionsToSave.push(requestObj);
                    }

                if (session.shiftFTech !== null && session.shiftFTech.id !== undefined) {                  
                    requestObj = {};
                        requestObj.outpatientSessionId = session.outpatientSessionId;
                        requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 20:00";
                        requestObj.endTime = this.$moment(dateToSave).add(1, "day").tz('America/Chicago').format('LL') + " 0:00";
                    requestObj.user = session.shiftFTech.id;
                        this.sessionsToSave.push(requestObj);
                    }
            },
            async saveSessionDetail(sessionData, dateToSave) {

                if (sessionData.modified == false)
                    return; 

                var requestObj = {};
                if (sessionData.shiftATech !== null && sessionData.shiftATech.id !== undefined) {
                    requestObj.outpatientSessionId = sessionData.outpatientSessionId;
                    requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 0:00";
                    requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 4:00";
                    requestObj.user = sessionData.shiftATech.id;  
                    await services.sessionDetailService
                        .create(requestObj)
                        .then(response => {
                            if (response) {
                                this.$store.commit(
                                    "snack/set_snack",
                                    "Schedule saved."
                                )
                            }
                        });
                }

                if (sessionData.shiftBTech !== null &&  sessionData.shiftBTech.id !== undefined) {
                    requestObj.outpatientSessionId = sessionData.outpatientSessionId;
                    requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 4:00";
                    requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 8:00";
                    requestObj.user = sessionData.shiftBTech.id;  
                    await services.sessionDetailService
                        .create(requestObj)
                        .then(response => {
                            if (response) {
                                this.$store.commit(
                                    "snack/set_snack",
                                    "Schedule saved."
                                )
                            }
                        });
                }

                if (sessionData.shiftCTech !== null &&  sessionData.shiftCTech.id !== undefined) {
                    requestObj.outpatientSessionId = sessionData.outpatientSessionId;
                    requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 8:00";
                    requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 12:00";
                    requestObj.user = sessionData.shiftCTech.id;  
                    await services.sessionDetailService
                        .create(requestObj)
                        .then(response => {
                            if (response) {
                                this.$store.commit(
                                    "snack/set_snack",
                                    "Schedule saved."
                                )
                            }
                        });
                }

                if (sessionData.shiftDTech !== null &&  sessionData.shiftDTech.id !== undefined) {
                    requestObj.outpatientSessionId = sessionData.outpatientSessionId;
                    requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 12:00";
                    requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 16:00";                    
                    requestObj.user = sessionData.shiftDTech.id;  
                    await services.sessionDetailService
                        .create(requestObj)
                        .then(response => {
                            if (response) {
                                this.$store.commit(
                                    "snack/set_snack",
                                    "Schedule saved."
                                )
                            }
                        });
                }

                if (sessionData.shiftETech !== null &&  sessionData.shiftETech.id !== undefined) {
                    requestObj.outpatientSessionId = sessionData.outpatientSessionId;
                    requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 16:00"
                    requestObj.endTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 20:00"
                    requestObj.user = sessionData.shiftETech.id;  
                    await services.sessionDetailService
                        .create(requestObj)
                        .then(response => {
                            if (response) {
                                this.$store.commit(
                                    "snack/set_snack",
                                    "Schedule saved."
                                )
                            }
                        });
                }

                if (sessionData.shiftFTech !== null &&  sessionData.shiftFTech.id !== undefined) {
                    requestObj.outpatientSessionId = sessionData.outpatientSessionId;
                    requestObj.startTime = this.$moment(dateToSave).tz('America/Chicago').format('LL') + " 20:00";
                    requestObj.endTime = this.$moment(dateToSave).add(1, "day").tz('America/Chicago').format('LL') + " 0:00";
                    requestObj.user = sessionData.shiftFTech.id;  
                    await services.sessionDetailService
                        .create(requestObj)
                        .then(response => {
                            if (response) {
                                this.$store.commit(
                                    "snack/set_snack",
                                    "Schedule saved."
                                )
                            }
                        });
                }                
            },
            async getUsers() {
                this.loading = true;
                var request = ToSearchPageRequest(this.searchText, this.options);
                await services.adminService.getAllUsers(request).then(data => {
                this.technicianList = data.results;
                }).finally(() => {
                    this.loading = false;
                });
            },
            formatDate(date) {
                return moment(date).format("YYYY-MM-DD");
            },
            setRowModified(item) {
                item.modified = true;
            }
        },
        async mounted() {
            this.calendarbuttonsenabled = false;  
            await this.getUsers();
            await this.getPendingSchedules();           
        },
    };
</script>
